//https://elastic-search-react-ui.pages.dev/

import React from "react";
import ReactDOM from "react-dom";
// TODO , add styles to match corteza
// import "./styles.css";
import SearchApp from "./SearchApp"


console.log("childSearchReady, sending request to parent to get credentials");
window.parent.postMessage({
    type: "childSearchReady"
}, "*");

window.addEventListener("message", (function(e) {
    if("searchReady" === e.data.type){
        console.log("child received credentials, rendering SearchApp");
        ReactDOM.render(
            <SearchApp host={e.data.host} userName={e.data.userName} password={e.data.password} defaultSearchTerm={e.data.searchTerm||""} />,
            document.getElementById("root")
        );
    }
}));