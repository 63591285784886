import React, {Fragment} from "react";
import "@elastic/eui/dist/eui_theme_light.css";

import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector"

import {
    ErrorBoundary,
    Facet,
    Paging,
    PagingInfo,
    Results,
    ResultsPerPage,
    SearchBox,
    SearchProvider,
    Sorting,
    WithSearch
} from "@elastic/react-search-ui";
import {Layout} from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";


const config = {
    debug: false,
    onSearch: (requestState, queryConfig, next) => {
        console.log("onSearch", requestState.searchTerm)
        window.parent.postMessage({
            type: "setUrlSearchTerm",
            searchTerm: requestState.searchTerm
        }, "*");
        return next(requestState, queryConfig);
    },
    alwaysSearchOnInitialLoad: true,
    apiConnector: null,
    hasA11yNotifications: true,
    searchQuery: {
        result_fields: {
            DisplayName_calc: {
                snippet: {
                    size: 75,
                }
            },
            link: {
                raw: {}
            },
            Phone: {
                snippet: {
                    size: 100,
                    fallback: true
                }
            },
            Email: {
                snippet: {
                    size: 100,
                    fallback: true
                }
            },
        },
    },
    autocompleteQuery: {
        results: {
            resultsPerPage: 5,
            result_fields: {
                DisplayName_calc: {
                    snippet: {
                        size: 100,
                        fallback: true
                    }
                },

            }
        },
        suggestions: {
            types: {
                documents: {
                    fields: ["DisplayName_calc"]
                }
            },
            size: 4
        }
    }
};
const CustomResultView = ({
                              result,
                              onClickLink
                          }) => {

    const snippetOrValueOrEmptyString = (field) => {
        if (result[field] && result[field].snippet) {
            return result[field].snippet;
        } else if (result[field] && result[field].raw) {
            return result[field].raw;
        } else {
            return "";
        }
    }
    let phone = snippetOrValueOrEmptyString("Phone");
    let email = snippetOrValueOrEmptyString("Email");
    if (phone) {
        phone = `<a href="tel:${result.Phone.raw}">${phone}</a>`
    }
    if (email) {
        email = `<a href="mailto:${result.Email.raw}">${email}</a>`
    }

    const onClick = () => {
        console.log('onClick', result.link.raw)
        window.parent.postMessage(
            {
                type: "openLink",
                result: result
            },
            "*"
        )
    }


    return (<li className="sui-result">
        <div className="sui-result__header">
            <h3>

                <button onClick={onClick}>
                    <span dangerouslySetInnerHTML={{__html: snippetOrValueOrEmptyString("DisplayName_calc")}}></span>
                </button>
            </h3>
        </div>
        <div className="sui-result__body">
            <div
                className="sui-result__details"
                dangerouslySetInnerHTML={{__html: email}}
            ></div>
            <div
                className="sui-result__details"
                dangerouslySetInnerHTML={{__html: phone}}
            ></div>
        </div>
    </li>);
};


const SORT_OPTIONS = [
    // todo, use  last submission status change date and updated_at
    {
        name: "DisplayName_calc",
        value: [
            {
                field: "DisplayName_calc",
                direction: "asc"
            }
        ]
    }
];

export default function SearchApp({userName, password,host,defaultSearchTerm}) {
    if (!userName || !password) {
        return <Fragment></Fragment>
    }
    const connector = new ElasticsearchAPIConnector({
        host: host,
        index: "merchant",
        connectionOptions: {
            headers: {
                "Authorization": "Basic " + btoa(`${userName}:${password}`),
            }
        },

    });
    config.apiConnector = connector;
    config.initialState = {
        searchTerm: defaultSearchTerm
    }

    return (
        <SearchProvider config={config}>
            <WithSearch
                mapContextToProps={({wasSearched}) => ({
                    wasSearched
                })}
            >
                {({wasSearched}) => {
                    return (
                        <div className="App">
                            <ErrorBoundary>
                                <Layout
                                    header={
                                        <SearchBox
                                            // Set debounceLength and searchAsYouType props
                                            debounceLength={300}
                                            searchAsYouType={true}
                                        />
                                    }
                                    sideContent={
                                        <div>
                                            {wasSearched && (
                                                <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS}/>
                                            )}
                                            <Facet
                                                field={"DisplayName_calc"}
                                                label="Name"
                                                filterType="any"
                                                isFilterable={true}
                                            />
                                            <Facet
                                                field={"Phone"}
                                                label="Phone"
                                                filterType="any"
                                                isFilterable={true}
                                            />
                                        </div>
                                    }
                                    bodyContent={
                                        <Results
                                            resultView={CustomResultView}
                                            titleField="DisplayName_calc"
                                            urlField="link"
                                            shouldTrackClickThrough={true}

                                        />
                                    }
                                    bodyHeader={
                                        <React.Fragment>
                                            {wasSearched && <PagingInfo/>}
                                            {wasSearched && <ResultsPerPage/>}
                                        </React.Fragment>
                                    }
                                    bodyFooter={<Paging/>}
                                />
                            </ErrorBoundary>
                        </div>
                    );
                }}
            </WithSearch>
        </SearchProvider>
    );
}
